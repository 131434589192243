
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/auth';
import { TextField, Button, Container, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode'; // Make sure to use `jwt-decode` for decoding JWT


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isMounted = true; // Track if the component is mounted

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, { email, password });

      if (isMounted) {
        const { token } = response.data;
        localStorage.setItem('token', token);
        if (token) {
          login(token);
          navigate('/dashboard');
        } else {
          console.error("Login Failed");
        }
      }
    } catch (error) {
      if (isMounted) {
        console.error('Login error:', error);
        alert('Invalid email or password');
      }
    }

    return () => {
      isMounted = false; // Set to false on component unmount
    };
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" component="h1" gutterBottom>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Login
        </Button>
      </form>
    </Container>
  );
};

export default Login;
