
// src/components/EditAsset.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MenuItem, FormControl, InputLabel, TextField, Button, Container, Typography, Grid, Select } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

const EditAsset = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [asset, setAsset] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [entities, setEntities] = useState([]);
  const [branches, setBranches] = useState([]);

  // States for form fields
  const [code, setCode] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [vendor, setVendor] = useState('');
  const [purchaseCost, setPurchaseCost] = useState('');
  const [oldLocation, setOldLocation] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [assetType, setAssetType] = useState('');
  const [purchaseDate, setPurchaseDate] = useState(dayjs());
  const [usefulLife, setUsefulLife] = useState('');
  const [custodian, setCustodian] = useState('');
  const [condition, setCondition] = useState('');
  const [description, setDescription] = useState('');
  const [entity, setEntity] = useState('');
  const [branch, setBranch] = useState('');

  useEffect(() => {
    // Fetch asset details
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/assets/${id}`)
      .then(response => {
        const asset = response.data;
        setAsset(asset);
        setCode(asset.code);
        setSerialNumber(asset.serialNumber);
        setVendor(asset.vendorId);
        setPurchaseCost(asset.purchaseCost);
        setOldLocation(asset.oldLocation);
        setNewLocation(asset.newLocation);
        setAssetType(asset.assetTypeId);
        setPurchaseDate(dayjs(asset.purchaseDate));
        setUsefulLife(asset.usefulLife);
        setCustodian(asset.custodian);
        setCondition(asset.condition);
        setDescription(asset.description);
        setEntity(asset.entityId);
        setBranch(asset.branchId);
      })
      .catch(error => console.error('Error fetching asset:', error));

    // Fetch vendors, asset types, entities, and branches
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/vendors`)
      .then(response => setVendors(response.data))
      .catch(error => console.error('Error fetching vendors:', error));

    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/assetTypes`)
      .then(response => setAssetTypes(response.data))
      .catch(error => console.error('Error fetching asset types:', error));

    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/entities`)
      .then(response => setEntities(response.data))
      .catch(error => console.error('Error fetching entities:', error));
    
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/branches`)
      .then(response => setBranches(response.data))
      .catch(error => console.error('Error fetching branches:', error));
  }, [id]);

  
const handleUpdateAsset = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/updateAsset/${id}`, {
      code,
      serialNumber,
      vendor,
      purchaseCost,
      oldLocation,
      newLocation,
      assetType,
      purchaseDate,
      usefulLife,
      custodian,
      condition,
      description,
      entity,
      branch
    });
    if (response.status === 200) {
      alert('Asset Updated!');
      navigate('/dashboard/assets');
    } else {
      alert('Asset Not Updated! Status Code: ' + response.status);
    }
  } catch (error) {
    console.error('Error updating asset:', error);
    alert('Asset Not Updated! Error: ' + (error.response?.data?.message || error.message));
  }
};


  return (
    <Container maxWidth="xl">
      <Typography variant="h4" component="h1" sx={{ mt: 2 }} gutterBottom>
        Edit Asset
      </Typography>
      <form onSubmit={handleUpdateAsset}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Code"
              variant="outlined"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Serial Number"
              variant="outlined"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Vendor</InputLabel>
              <Select
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
                label="Vendor"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {vendors.map((vendor) => (
                  <MenuItem key={vendor.id} value={vendor.id}>{vendor.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Purchase Cost"
              variant="outlined"
              value={purchaseCost}
              onChange={(e) => setPurchaseCost(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Old Location"
              variant="outlined"
              value={oldLocation}
              onChange={(e) => setOldLocation(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="New Location"
              variant="outlined"
              value={newLocation}
              onChange={(e) => setNewLocation(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Asset Type</InputLabel>
              <Select
                value={assetType}
                onChange={(e) => setAssetType(e.target.value)}
                label="Asset Type"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {assetTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Purchase Date"
                value={purchaseDate}
                onChange={(newValue) => setPurchaseDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" variant="outlined" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Useful Life"
              variant="outlined"
              value={usefulLife}
              onChange={(e) => setUsefulLife(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Custodian"
              variant="outlined"
              value={custodian}
              onChange={(e) => setCustodian(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Condition</InputLabel>
              <Select
                value={condition}
                onChange={(e) => setCondition(e.target.value)}
                label="Condition"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Good">Good</MenuItem>
                <MenuItem value="Fair">Fair</MenuItem>
                <MenuItem value="Bad">Bad</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Entity</InputLabel>
              <Select
                value={entity}
                onChange={(e) => setEntity(e.target.value)}
                label="Entity"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {entities.map((entity) => (
                  <MenuItem key={entity.id} value={entity.id}>{entity.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Branch</InputLabel>
              <Select
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                label="Branch"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" type="submit">
            Update Asset
          </Button>
        </Grid>
      </form>
    </Container>
  );
};

export default EditAsset;
