
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const EntityList = () => {
  const [entities, setEntities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    // Fetch entities from the API
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/entities`, { signal })
      .then(response => setEntities(response.data))
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error fetching entities:', error);
        }
      });

    return () => {
      controller.abort(); // Cleanup: Cancel the request if the component unmounts
    };
  }, []);

  const handleAddEntity = () => {
    // Navigate to the add entity page
    navigate('/dashboard/addEntity');
  };

  const handleEditEntity = (entityId) => {
    navigate(`/dashboard/editEntity/${entityId}`);
  };

  const handleDeleteEntity = (entityId) => {
    // Implement delete functionality here
    console.log('Delete Entity', entityId);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Entities
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddEntity}
        style={{ marginBottom: '16px', float: 'right' }}
      >
        Add Entity
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entities.map((entity) => (
              <TableRow key={entity.id}>
                <TableCell>{entity.name}</TableCell>
                <TableCell>{entity.description}</TableCell>
                <TableCell>{entity.phone}</TableCell>
                <TableCell>{entity.address}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditEntity(entity.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteEntity(entity.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default EntityList;
