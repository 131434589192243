
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MenuItem, FormControl, InputLabel, TextField, Button, Container, Typography, Grid, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Register = () => {
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [lastname, setLastname] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('');
  const [entity, setEntity] = useState('');
  const [branch, setBranch] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [roles, setRoles] = useState([]);
  const [entities, setEntities] = useState([]);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        const [rolesRes, entitiesRes, branchesRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BASE_URL}/auth/roles`, { signal }),
          axios.get(`${process.env.REACT_APP_BASE_URL}/auth/entities`, { signal }),
          axios.get(`${process.env.REACT_APP_BASE_URL}/auth/branches`, { signal }),
        ]);

        setRoles(rolesRes.data);
        setEntities(entitiesRes.data);
        setBranches(branchesRes.data);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Request was cancelled:', error.message);
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();

    // Cleanup function to cancel ongoing requests when the component unmounts
    return () => {
      controller.abort();
    };
  }, []);

  const validateForm = () => {
    let isValid = true;

    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
      isValid = false;
    } else {
      setPasswordError('');
    }

    return isValid;
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/register`, {
          firstname, middlename, lastname, username, email, password, role, entity, branch
        });
        alert('Registration successful');
        navigate('/dashboard/users');
      } catch (error) {
        alert('Registration failed');
      }
    }
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" component="h1" sx={{ mt: 2 }} gutterBottom>
        Register
      </Typography>
      <form onSubmit={handleRegister}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="First Name"
              variant="outlined"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Middle Name"
              variant="outlined"
              value={middlename}
              onChange={(e) => setMiddlename(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Last Name"
              variant="outlined"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              error={!!emailError}
              helperText={emailError}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Confirm Password"
              type="password"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              error={!!passwordError}
              helperText={passwordError}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Role</InputLabel>
              <Select value={role} onChange={(e) => setRole(e.target.value)} required>
                {roles.map((roleItem) => (
                  <MenuItem key={roleItem.id} value={roleItem.name}>
                    {roleItem.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Entity</InputLabel>
              <Select value={entity} onChange={(e) => setEntity(e.target.value)} required>
                {entities.map((entityItem) => (
                  <MenuItem key={entityItem.id} value={entityItem.name}>
                    {entityItem.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Branch</InputLabel>
              <Select value={branch} onChange={(e) => setBranch(e.target.value)} required>
                {branches.map((branchItem) => (
                  <MenuItem key={branchItem.id} value={branchItem.name}>
                    {branchItem.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Button fullWidth variant="contained" color="primary" type="submit">
              Register
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Register;
