
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Grid, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AddBranch = () => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [entityId, setEntityId] = useState('');
  const [entities, setEntities] = useState([]);
  const navigate = useNavigate();

  // Fetch entities list when the component mounts
  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/entities`)
      .then(response => {
        if (isMounted) {
          setEntities(response.data);
        }
      })
      .catch(error => {
        if (isMounted) {
          console.error('Error fetching entities:', error);
        }
      });

    return () => {
      isMounted = false; // Cleanup function to prevent memory leaks
    };
  }, []);

  const handleAddBranch = async (e) => {
    e.preventDefault();
    let isMounted = true;

    try {
      console.log('Sending data:', { name, address, entityId }); // Debugging line
      await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/addBranch`, { name, address, entityId });
      if (isMounted) {
        alert('Branch Added!');
        navigate('/dashboard/branches');
      }
    } catch (error) {
      if (isMounted) {
        console.error('Error adding branch:', error); // Debugging line
        alert('Branch Not Added!');
      }
    }

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false when the component unmounts
    };
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" sx={{ mt: 2 }} gutterBottom>
        Add Branch
      </Typography>
      <form onSubmit={handleAddBranch}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Branch Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Address"
              variant="outlined"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Entity</InputLabel>
              <Select
                value={entityId}
                onChange={(e) => setEntityId(e.target.value)}
                label="Entity"
                required
              >
                {entities.map((entity) => (
                  <MenuItem key={entity.id} value={entity.id}>
                    {entity.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Add Branch
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddBranch;
